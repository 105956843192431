import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './pickcard-profile.css';
import logoImg from '../../assets/logo.png';
import userPic from './pickcard-profile.jpeg'; // Twój obrazek użytkownika
import { useTranslation } from 'react-i18next';

function PickcardPage() {
  const { t, i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isLangMenuOpen, setIsLangMenuOpen] = useState(false);
  const [base64Photo, setBase64Photo] = useState('');
  const [isAndroid, setIsAndroid] = useState(false); // Nowy stan dla Androida

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  const handleLangChange = (lng) => {
    i18n.changeLanguage(lng);
    setIsLangMenuOpen(false);
  };

  const toggleLangMenu = () => {
    setIsLangMenuOpen(!isLangMenuOpen);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
        setIsOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    // Sprawdzenie, czy użytkownik korzysta z Androida
    const isAndroidDevice = /Android/i.test(navigator.userAgent);
    setIsAndroid(isAndroidDevice);

    // Konwersja obrazka na Base64
    const convertImageToBase64 = (image) => {
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onloadend = () => {
        setBase64Photo(reader.result.split(',')[1]); // Usunięcie nagłówka data:image/jpeg;base64,
      };
    };

    fetch(userPic)
      .then(res => res.blob())
      .then(blob => {
        convertImageToBase64(blob);
      });
  }, [userPic]);

  const handleDownload = () => {
    // Sprawdzanie systemu operacyjnego
    const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);

    let vCardData;

    if (isIOS) {
      // vCard dla iOS (z Base64 zdjęciem)
      vCardData = `
BEGIN:VCARD
VERSION:3.0
FN;CHARSET=UTF-8;ENCODING=QUOTED-PRINTABLE:Imie Nazwisko
N;CHARSET=UTF-8;ENCODING=QUOTED-PRINTABLE:Nazwisko;Imie;;;
ORG;CHARSET=UTF-8;ENCODING=QUOTED-PRINTABLE:PICKCARD
TITLE;CHARSET=UTF-8;ENCODING=QUOTED-PRINTABLE:Graphic Designer
TEL;TYPE=CELL,VOICE:+48 669 206 212
EMAIL;TYPE=INTERNET:info@pickcard.pl
URL:https://www.pickcard.pl
PHOTO;ENCODING=b;TYPE=JPEG:${base64Photo}
NOTE;CHARSET=UTF-8;ENCODING=QUOTED-PRINTABLE:Business Card made by PICKCARD.PL
END:VCARD
      `;
    } else {
      // vCard dla innych systemów (np. Windows, macOS)
      vCardData = `
BEGIN:VCARD
VERSION:3.0
FN;CHARSET=UTF-8;ENCODING=QUOTED-PRINTABLE:Bartosz Muszy=C5=84ski
N;CHARSET=UTF-8;ENCODING=QUOTED-PRINTABLE:Muszy=C5=84ski;Bartosz;;;
ORG;CHARSET=UTF-8;ENCODING=QUOTED-PRINTABLE:HomeSelect
TITLE;CHARSET=UTF-8;ENCODING=QUOTED-PRINTABLE:Real Estate Advisor
TEL;TYPE=CELL,VOICE:+48 666 814 700
EMAIL;TYPE=INTERNET:barmusz8@wp.pl
URL:https://www.homeselect.pl
NOTE;CHARSET=UTF-8;ENCODING=QUOTED-PRINTABLE:Business Card made by PICKCARD.PL
END:VCARD
      `;
    }
    
    const blob = new Blob([vCardData], { type: 'text/vcard;charset=utf-8' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'Bartosz_Muszynski_contact.vcf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  return (
    <div className='animation-pickcard-profile'>
      <div className="UserProfile-pickcard-profile">
        <nav className="nav-pickcard-profile">
          <Link to="/" className='nav-link-pickcard-profile'>
            <img src={logoImg} alt="PICKCARD Logo" />
          </Link>
          <div className="language-slider-pickcard-profile">
            <li className="lang-menu2-pickcard-profile" onClick={toggleLangMenu}>
              <span className="selected-lang2-pickcard-profile">{i18n.language.toUpperCase()}</span>
              {isLangMenuOpen && (
                <ul className="lang-options2-pickcard-profile">
                  <li onClick={() => handleLangChange('pl')}>PL</li>
                  <li onClick={() => handleLangChange('en')}>EN</li>
                  <li onClick={() => handleLangChange('de')}>DE</li>
                  <li onClick={() => handleLangChange('es')}>ES</li>
                </ul>
              )}
            </li>
          </div>
        </nav>
        <div className="container-profile-pickcard-profile">
          <aside className="profile-card-pickcard-profile">
            <header>
              <a href="#">
                <img src={userPic} className="hoverZoomLink-pickcard-profile" alt="Avatar" />
              </a>
              <h1 className="h1-pickcard-profile">Imię i Nazwisko</h1>
              <h2 className="h2-pickcard-profile">{t("Profession-Pickcard")}</h2>
            </header>

            <div className="profile-bio-pickcard-profile">
              <p>{t("Profile-Text-Pickcard")}</p>
              <p className="bio-text2-pickcard-profile"><br></br></p>
              <p className="bio-text2-pickcard-profile">TWÓJ NUMER TELEFONU</p>
              <p className="bio-text2-pickcard-profile">TWÓJ ADRES EMAIL</p>
              <p className="bio-text2-pickcard-profile">ORAZ DOWOLNE SOCIAL MEDIA</p>
            </div>

            <ul
              className={`profile-social-links-pickcard-profile ${
                isAndroid ? 'android-padding' : ''
              }`}
            >
              <li>
                <a href="tel:+48 669 206 212" id="phoneLink-pickcard-profile">
                  <i className="fas fa-phone"></i>
                </a>
                <div id="phoneNumber-pickcard-profile"></div>
              </li>
              <li>
                <a target="_blank" href="mailto:info@pickcard.pl">
                  <i className="fa-solid fa-envelope"></i>
                </a>
              </li>
              <li>
                <a target="_blank" href="https://www.pickcard.pl">
                  <i className="fa-solid fa-globe"></i>
                </a>
              </li>
              <li>
                <a target="_blank" href="https://wa.me/+48669206212">
                  <i className="fa-brands fa-whatsapp"></i>
                </a>
              </li>
            </ul>

            {!isAndroid && ( // Ukryj przycisk na Androidzie
              <button onClick={handleDownload} className="download-vcard-button-pickcard-profile">
                Download Contact
              </button>
            )}
          </aside>
        </div>
      </div>
    </div>
  );
}

export default PickcardPage;

import React from 'react';
import { Link } from 'react-router-dom';
import Navigation from '../components/Navigation/Navigation';
import HeroSection from '../components/HeroSection/HeroSection';
import AboutUs from '../components/AboutUs/AboutUs';
import WhyUs from '../components/WhyUs/WhyUs';
import Products from '../components/Products/Products';
import ContactForm from '../components/ContactForm/Contact';
import Cennik from '../components/CENNIK/Cennik';
import Footer from '../components/Footer/Footer';
import '../pages/HomePage.css';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';

function HomePage() {
    const { t } = useTranslation();

    return (
        <>
            <Helmet>
                <title>PICKCARD</title>
                <meta name="description" content="Interaktywne wizytówki tworzone z użyciem technologii NFC" />
                <link rel="canonical" href="/" />
            </Helmet>

            <div className="App" id="top">
                <Navigation />
                <HeroSection />
                <div style={{ height: '50px', backgroundColor: '#101010' }}></div>

                <div className="section-separator" id="About">
                    {t("About")}
                </div>
                <AboutUs />
                <WhyUs />
                <div className="section-separator" id="Products">
                    {t("Products")}
                </div>
                <Products />
                {/* <div className="section-separator" id="Price">
                    {t("Price")}
                </div>
                <Cennik /> */}
                <div className="section-separator" id="Contact">
                    {t("Contact")}
                </div>
                <ContactForm />
                <Footer />
            </div>
        </>
    );
}

export default HomePage;
